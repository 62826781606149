import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <g stroke="#4468FF" strokeWidth="3">
          <path d="M9 18.071v21h30v-21" />
          <path d="M9 17.584c0 2.082 1.679 3.77 3.75 3.77 2.071 0 3.75-1.688 3.75-3.77M16.5 17.584c0 2.082 1.679 3.77 3.75 3.77 2.071 0 3.75-1.688 3.75-3.77M24 17.584c0 2.082 1.679 3.77 3.75 3.77 2.071 0 3.75-1.688 3.75-3.77M31.5 17.584c0 2.082 1.679 3.77 3.75 3.77 2.071 0 3.75-1.688 3.75-3.77" />
          <path d="M9 17.584V8.97h30v8.615" />
          <path strokeLinecap="square" d="M16.5 17.771v-8.81M24 17.771v-8.81M31.5 17.771v-8.81" />
        </g>
        <path stroke="#4468FF" strokeWidth="3" strokeLinecap="square" d="M42 30.5H6" />
      </g>
    </svg>
  );
};

export default React.memo(Icon);
