import React, { useState } from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import JoinForm from '../components/Forms/Join';
import Modal from '../components/Modal/index';
import Numbers from '../components/Numbers/Numbers';
import ArrowIcon from '../images/Arrow';
import RevenueIcon from '../images/Icons/Icon-15';
import MarketIcon from '../images/Icons/Icon-16';
import EffortlessIcon from '../images/Icons/Icon-17';
import illustrationImage from '../images/Illustrations/list-management.svg';
import familyImage from '../images/ListManagement/family.jpg';

import style from '../styles/pages/listManagement.scss';

import { Link } from 'gatsby';

interface IListManagementProps {
  data: {
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
}

const ListManagement = ({ location }: IListManagementProps) => {
  const [isJoinModalShown, setJoinModalShown] = useState(false);

  // Join Modal Toggle
  const showJoinModal = () => {
    setJoinModalShown(true);
  };

  const hideJoinModal = () => {
    setJoinModalShown(false);
  };

  return (
    <>
      <MetaData
        location={location}
        data={{}}
        title="The Latest Personal Finance Content from Think Save Retire"
        description="Read the latest personal finance information on Think Save Retire including stories of reaching financial goals such as retiring by 40 or building wealth."
      />
      <Modal
        className={style.joinModal}
        isShown={isJoinModalShown}
        isCloseButtonShown
        onClose={hideJoinModal}
      >
        <JoinForm />
      </Modal>

      <Layout isHome>
        <section className={style.hero}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className={style.breadcrumb}>
                  <Link to="/">Home</Link>
                  <ArrowIcon rotate={180} />
                  <span>List Management</span>
                </div>
                <img
                  className="d-none d-lg-block"
                  src={illustrationImage}
                  alt="Illustration Image"
                />
              </div>
              <div className="col-12 col-lg-8">
                <h1>
                  Monetize your data
                  <small>
                    Data providers play a vital role in the Lead Economy. The more data, the more
                    traffic — the more traffic, the more leads. Leverage our marketing expertise to
                    maximize your return on your data.
                  </small>
                </h1>
                <PrimaryButton onClick={showJoinModal} className={style.primaryButton}>
                  Let’s Work Together
                </PrimaryButton>
                <div className="row">
                  <img className="d-lg-none" src={illustrationImage} alt="Illustration Image" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={style.family}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-7 col-xl-8 offset-lg-1 order-lg-last">
                <img src={familyImage} alt="Family" />
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-12">
                    <RevenueIcon />
                    <h5>New Revenue Stream</h5>
                    <p>Monetize your data to create a new revenue stream for your business.</p>
                  </div>
                  <div className="col-12 col-md-4 col-lg-12">
                    <MarketIcon />
                    <h5>New Markets</h5>
                    <p>
                      Tap into multiple verticals to monetize your data with our wide variety of
                      financial offers.
                    </p>
                  </div>
                  <div className="col-12 col-md-4 col-lg-12">
                    <EffortlessIcon />
                    <h5>Effortless API</h5>
                    <p>
                      Zero set-up fees and automatic payments make earning revenue easy and
                      streamlined.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Numbers />
        <BigBanner />
      </Layout>
    </>
  );
};

export default ListManagement;
