import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <g stroke="#4468FF" strokeWidth="3">
          <path d="M8 19.449l3.162 2.598M35.507 22.484V16.71l-4.94 1.517C28.37 16.95 25.71 16.2 22.836 16.2c-7.525 0-13.627 5.13-13.627 11.46 0 2.5.963 4.806 2.58 6.688l-1.204 6.265h6.466l.874-2.274c1.525.496 3.177.78 4.911.78 1.099 0 2.156-.137 3.18-.344l.705 1.838h6.468l-.91-4.74c1.433-2.051 3.472-3.357 6.115-3.919l.653-8.133-3.54-1.337zM26.7 20.847c-4.047-1.398-7.309-.318-8.786.346" />
          <path d="M27.414 11.106a5.106 5.106 0 1 1-10.213-.001 5.106 5.106 0 0 1 10.213 0z" />
        </g>
      </g>
    </svg>
  );
};

export default React.memo(Icon);
