import React from 'react';

import illustrationImage from '../../images/Banners/illustration.svg';

import style from './Numbers.scss';

import cn from 'classnames';

interface INumbersProps {
  className?: string;
}

export default function Numbers(props: INumbersProps) {
  return (
    <section className={cn(props.className, style.numbers)}>
      <img src={illustrationImage} alt="Illustration" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>What numbers say</h2>
          </div>
          <div className="col-6 col-md-3">
            <h3>$250+</h3>
            <h5>Top lead price</h5>
          </div>
          <div className="col-6 col-md-3">
            <h3>300K+</h3>
            <h5>Visitors per today</h5>
          </div>
          <div className="col-6 col-md-3">
            <h3>350+</h3>
            <h5>Active offers</h5>
          </div>
          <div className="col-6 col-md-3">
            <h3>100+</h3>
            <h5>Lenders</h5>
          </div>
        </div>
      </div>
    </section>
  );
}
