import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <g stroke="#4468FF" strokeWidth="3">
          <path d="M16.464 27.969l-4.186-8.726-4.187 8.726M6 27.969a6.437 6.437 0 0 0 12.626 0H6zM39.185 21.345L35 12.617l-4.187 8.728M28.72 21.345a6.437 6.437 0 0 0 12.626 0H28.72zM23.677 10v28.262M12.09 19.899L35.01 12.7M12.677 37.895H34.69" />
        </g>
      </g>
    </svg>
  );
};

export default React.memo(Icon);
